import {
  POST_NEW_MAINTENANCE,
  POST_NEW_MAINTENANCE_SUCCESS,
  POST_NEW_MAINTENANCE_ERROR,
  UPDATE_MAINTENANCE,
  UPDATE_MAINTENANCE_SUCCESS,
  UPDATE_MAINTENANCE_ERROR,
  DELETE_MAINTENANCE,
  DELETE_MAINTENANCE_SUCCESS,
  DELETE_MAINTENANCE_ERROR,
} from './actions';

const initialState = {
  allEvents: {},
  loading: false,
  postError: null,
  updateError: null,
  deleteError: null,
  postResponse: null,
  updateResponse: null,
  deleteResponse: null,
};

const maitenanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_NEW_MAINTENANCE:
    case UPDATE_MAINTENANCE:
    case DELETE_MAINTENANCE:
      return {
        ...state,
        loading: true,
        postError: null,
        updateError: null,
        deleteError: null,
      };

    case POST_NEW_MAINTENANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        postResponse: action.payload,
      };

    case UPDATE_MAINTENANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        updateResponse: action.payload,
      };

    case DELETE_MAINTENANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteResponse: action.payload,
      };

    case POST_NEW_MAINTENANCE_ERROR:
      return {
        ...state,
        loading: false,
        postError: action.payload,
      };

    case UPDATE_MAINTENANCE_ERROR:
      return {
        ...state,
        loading: false,
        updateError: action.payload,
      };

    case DELETE_MAINTENANCE_ERROR:
      return {
        ...state,
        loading: false,
        deleteError: action.payload,
      };

    default:
      return state;
  }
};

export default maitenanceReducer;
