import { takeLatest, call, put } from 'redux-saga/effects';
import axios from 'axios';
import {
  POST_NEW_MACHINE,
  postNewMachineSuccess,
  postNewMachineError,
  UPDATE_MACHINE,
  updateMachineSuccess,
  updateMachineError,
  DELETE_MACHINE,
  deleteMachineSuccess,
  deleteMachineError,
  VERIFY_DATA,
  verifyData,
  POST_MULTIPLE_MACHINES,
  postMultipleMachinesSuccess,
  postMultipleMachinesError,
} from './actions';
import { apiPath } from '../../constants/defaultValues';

function* postNewMachineSaga(action) {
  try {
    const response = yield call(
      axios.post,
      `${apiPath}/machines`,
      action.payload
    );
    const responseData = response.data;
    console.log('Saga post:', responseData);
    yield put(postNewMachineSuccess(responseData));
  } catch (error) {
    yield put(postNewMachineError(error));
  }
}

function* postMultipleMachinesSaga(action) {
  try {
    const response = yield call(
      axios.post,
      `${apiPath}/multiple_machines`,
      action.payload,
      { headers: { 'Content-Type': 'application/json' } }
    );
    const responseData = response.data;
    yield put(postMultipleMachinesSuccess(responseData));
  } catch (error) {
    console.error('Error en la llamada a la API:', error.response.data);
    yield put(postMultipleMachinesError(error));
  }
  
}

function* updateMachine(action) {
  try {
    const response = yield call(
      axios.put,
      `${apiPath}/machines`,
      action.payload
    );
    console.log('Saga update:', response.data);

    const responseData = response.data;
    yield put(updateMachineSuccess(responseData));
  } catch (error) {
    console.log('Response error update:', error.response.data);
    yield put(updateMachineError(error));
  }
}

function* deleteMachineSaga(action) {
  const machineId = action.payload;
  try {
    const response = yield call(
      axios.delete,
      `${apiPath}/machines?ID=${machineId}`
    );
    console.log('Saga delete:', response.data);
    const responseData = response.data;
    yield put(deleteMachineSuccess(responseData));
  } catch (error) {
    console.error('Response error delete:', error.response.data);
    yield put(deleteMachineError(error));
  }
}

export const watchPostNewMachine = function* () {
  yield takeLatest(POST_NEW_MACHINE, postNewMachineSaga);
};

export const watchUpdateMachine = function* () {
  yield takeLatest(UPDATE_MACHINE, updateMachine);
};

export function* watchDeleteMachine() {
  yield takeLatest(DELETE_MACHINE, deleteMachineSaga);
}

export const watchVerifyData = function* () {
  yield takeLatest(VERIFY_DATA, verifyData);
};

export const watchPostMultipleMachines = function* () {
  yield takeLatest(POST_MULTIPLE_MACHINES, postMultipleMachinesSaga);
};
