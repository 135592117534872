import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import axios from 'axios';
import sha512 from 'js-sha512';
import ReactGA from 'react-ga4';
import { auth } from '../../helpers/Firebase';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from '../actions';
import { apiPath, adminRoot, currentUser } from '../../constants/defaultValues';

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  changePasswordSuccess,
  changePasswordError,
} from './actions';

import { setCurrentUser } from '../../helpers/Utils';

const apiUrl = `${apiPath}/login`;
const apiUrlChangePass = `${apiPath}/change_pwd`;

export function* watchLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) =>
  // eslint-disable-next-line no-return-await
  await axios
    .post(`${apiUrl}`, {
      email: `${email}`,
      password: sha512(`${password}`),
    })
    .then((res) => res.data)
    .catch((error) => error);

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    if (loginUser.codigo === 200) {
      const item = {
        uid: loginUser.mensaje.id,
        alquilador: loginUser.mensaje.alquilador,
        alquiladorID: loginUser.mensaje.alquiladorID,
        gestionEZ: loginUser.mensaje.gestionEZ,
        email,
        ...currentUser,
      };
      setCurrentUser(item);
      yield put(loginUserSuccess(item));
      history.push(adminRoot);
      ReactGA.event({
        category: 'User',
        action: 'Login',
        label: 'Login del Usuario',
        userProperty: `Alquilador: ${loginUser.mensaje.alquilador}, 
        Id: ${loginUser.mensaje.alquiladryorID}, UniqueID: ${loginUser.mensaje.id} Mail: ${email}`,
        successful: !loginUser.error,
        status: loginUser.codigo
      });
    } else {
      ReactGA.event({
        category: 'User',
        action: 'Login',
        successful: 'false',
        status: 400,
        mensaje: 'Invalid username or password'
      });
      yield put(loginUserError('Contraseña erronea'));
    }
      } catch (error) {
    yield put(loginUserError(error));
  }
}

export function* watchRegisterUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email, password) =>
  // eslint-disable-next-line no-return-await
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then((user) => user)
    .catch((error) => error);

function* registerWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password
    );
    if (!registerUser.message) {
      const item = { uid: registerUser.user.uid, ...currentUser };
      setCurrentUser(item);
      yield put(registerUserSuccess(item));
      history.push(adminRoot);
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  await auth
    .signOut()
    .then((user) => user)
    .catch((error) => error);
  history.push(adminRoot);
};

function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser();
  yield call(logoutAsync, history);
}

export function* watchChangePassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(CHANGE_PASSWORD, changePassword);
}

const changePasswordAsync = async (email, newPassword, currentPassword) =>
  // eslint-disable-next-line no-return-await
  await axios
    .post(`${apiUrlChangePass}`, {
      email: `${email}`,
      newPassword: sha512(`${newPassword}`),
      currentPassword: sha512(`${currentPassword}`),
    })
    .then((res) => res.data)
    .catch((error) => error);

function* changePassword({ payload }) {
  const { email, newPassword, currentPassword } = payload;
  try {
    const changePass = yield call(
      changePasswordAsync,
      email,
      newPassword,
      currentPassword
    );
    if (changePass.codigo === 200) {
      yield put(changePasswordSuccess('success'));
    } else {
      yield put(changePasswordError(changePass.mensaje));
    }
  } catch (error) {
    yield put(changePasswordError(error));
  }
}

export function* watchForgotPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  // eslint-disable-next-line no-return-await
  return await auth
    .sendPasswordResetEmail(email)
    .then((user) => user)
    .catch((error) => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  // eslint-disable-next-line no-return-await
  return await auth
    .confirmPasswordReset(resetPasswordCode, newPassword)
    .then((user) => user)
    .catch((error) => error);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchChangePassword),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ]);
}
