export const POST_NEW_EVENT = 'POST_NEW_EVENT';
export const POST_NEW_EVENT_SUCCESS = 'POST_NEW_EVENT_SUCCESS';
export const POST_NEW_EVENT_ERROR = 'POST_NEW_EVENT_ERROR';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_ERROR = 'UPDATE_EVENT_ERROR';
export const DELETE_EVENT = 'DELETE_EVENT';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_ERROR = 'DELETE_EVENT_ERROR';

export const postNewEvent = (payload) => {
  return {
    type: POST_NEW_EVENT,
    payload,
  };
};

export const postNewEventSuccess = (responseData) => ({
  type: POST_NEW_EVENT_SUCCESS,
  payload: responseData,
});

export const postNewEventError = (error) => ({
  type: POST_NEW_EVENT_ERROR,
  payload: error,
});

export const updateEvent = (payload) => {
  return {
    type: UPDATE_EVENT,
    payload,
  };
};

export const updateEventSuccess = (responseData) => ({
  type: UPDATE_EVENT_SUCCESS,
  payload: responseData,
});

export const updateEventError = (error) => ({
  type: UPDATE_EVENT_ERROR,
  payload: error,
});

export const deleteEvent = (payload) => {
  return {
    type: DELETE_EVENT,
    payload,
  };
};

export const deleteEventSuccess = (responseData) => ({
  type: DELETE_EVENT_SUCCESS,
  payload: responseData,
});

export const deleteEventError = (error) => ({
  type: DELETE_EVENT_ERROR,
  payload: error,
});
