import { takeLatest, call, put } from 'redux-saga/effects';
import axios from 'axios';
import {
  POST_NEW_EVENT,
  postNewEventSuccess,
  postNewEventError,
  UPDATE_EVENT,
  updateEventSuccess,
  updateEventError,
  DELETE_EVENT,
  deleteEventSuccess,
  deleteEventError,
} from './actions';
import { apiPath } from '../../constants/defaultValues';

function* postNewEventSaga(action) {
  try {
    const response = yield call(
      axios.post,
      `${apiPath}/calendar`,
      action.payload
    );
    const responseData = response.data;
    console.log('Post evento reserva saga', responseData);

    yield put(postNewEventSuccess(responseData));
  } catch (error) {
    yield put(postNewEventError(error));
  }
}

function* updateEventSaga(action) {
  try {
    const response = yield call(
      axios.put,
      `${apiPath}/calendar`,
      action.payload
    );

    const responseData = response.data;
    console.log('Put evento reserva saga', responseData);

    yield put(updateEventSuccess(responseData));
  } catch (error) {
    yield put(updateEventError(error));
  }
}

function* deleteEventSaga(action) {
  try {
    const response = yield call(axios.delete, `${apiPath}/calendar`, {
      params: { ID: action.payload },
    });

    const responseData = response.data;
    console.log('delete evento reserva saga', responseData);

    yield put(deleteEventSuccess(responseData));
  } catch (error) {
    yield put(deleteEventError(error));
  }
}

export const watchPostNewEvent = function* () {
  yield takeLatest(POST_NEW_EVENT, postNewEventSaga);
};

export const watchUpdateEvent = function* () {
  yield takeLatest(UPDATE_EVENT, updateEventSaga);
};

export const watchDeleteEvent = function* () {
  yield takeLatest(DELETE_EVENT, deleteEventSaga);
};
