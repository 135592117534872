export const POST_NEW_MAINTENANCE = 'POST_NEW_MAINTENANCE';
export const POST_NEW_MAINTENANCE_SUCCESS = 'POST_NEW_MAINTENANCE_SUCCESS';
export const POST_NEW_MAINTENANCE_ERROR = 'POST_NEW_MAINTENANCE_ERROR';
export const UPDATE_MAINTENANCE = 'UPDATE_MAINTENANCE';
export const UPDATE_MAINTENANCE_SUCCESS = 'UPDATE_MAINTENANCE_SUCCESS';
export const UPDATE_MAINTENANCE_ERROR = 'UPDATE_MAINTENANCE_ERROR';
export const DELETE_MAINTENANCE = 'DELETE_MAINTENANCE';
export const DELETE_MAINTENANCE_SUCCESS = 'DELETE_MAINTENANCE_SUCCESS';
export const DELETE_MAINTENANCE_ERROR = 'DELETE_MAINTENANCE_ERROR';

export const postNewMaintenance = (payload) => {
  return {
    type: POST_NEW_MAINTENANCE,
    payload,
  };
};

export const postNewMaintenanceSuccess = (responseData) => ({
  type: POST_NEW_MAINTENANCE_SUCCESS,
  payload: responseData,
});

export const postNewMaintenanceError = (error) => ({
  type: POST_NEW_MAINTENANCE_ERROR,
  payload: error,
});

export const updateMaintenance = (payload) => {
  return {
    type: UPDATE_MAINTENANCE,
    payload,
  };
};
export const updateMaintenanceSuccess = (responseData) => ({
  type: UPDATE_MAINTENANCE_SUCCESS,
  payload: responseData,
});

export const updateMaintenanceError = (error) => {
  return {
    type: UPDATE_MAINTENANCE_ERROR,
    payload: error.response.data,
  };
};

export const deleteMaintenance = (payload) => {
  return {
    type: DELETE_MAINTENANCE,
    payload,
  };
};

export const deleteMaintenanceSuccess = (responseData) => ({
  type: DELETE_MAINTENANCE_SUCCESS,
  payload: responseData,
});

export const deleteMaintenanceError = (error) => {
  return {
    type: DELETE_MAINTENANCE_ERROR,
    payload: error.response.data,
  };
};
