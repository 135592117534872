import { takeLatest, call, put } from 'redux-saga/effects';
import axios from 'axios';
import {
  POST_NEW_MAINTENANCE,
  postNewMaintenanceSuccess,
  postNewMaintenanceError,
  UPDATE_MAINTENANCE,
  updateMaintenanceSuccess,
  updateMaintenanceError,
  DELETE_MAINTENANCE,
  deleteMaintenanceSuccess,
  deleteMaintenanceError,
} from './actions';
import { apiPath } from '../../constants/defaultValues';

function* postNewMaintenanceSaga(action) {
  try {
    const response = yield call(
      axios.post,
      `${apiPath}/mantenimiento`,
      action.payload
    );
    console.log('Post maitenance saga', response.data);
    const responseData = response.data;
    yield put(postNewMaintenanceSuccess(responseData));
  } catch (error) {
    yield put(postNewMaintenanceError(error));
  }
}

function* updateMaintenanceSaga(action) {
  try {
    const response = yield call(
      axios.put,
      `${apiPath}/mantenimiento`,
      action.payload
    );

    const responseData = response.data;
    console.log('Put evento maitenance saga', responseData);
    yield put(updateMaintenanceSuccess(responseData));
  } catch (error) {
    yield put(updateMaintenanceError(error));
  }
}

function* deleteMaintenanceSaga(action) {
  try {
    const response = yield call(axios.delete, `${apiPath}/mantenimiento`, {
      params: { id: action.payload },
    });
    const responseData = response.data;
    console.log('delete evento maitenance saga', responseData);

    yield put(deleteMaintenanceSuccess(responseData));
  } catch (error) {
    yield put(deleteMaintenanceError(error));
  }
}
export const watchPostNewMaintenance = function* () {
  yield takeLatest(POST_NEW_MAINTENANCE, postNewMaintenanceSaga);
};

export const watchUpdateMaintenance = function* () {
  yield takeLatest(UPDATE_MAINTENANCE, updateMaintenanceSaga);
};

export const watchDeleteMaintenance = function* () {
  yield takeLatest(DELETE_MAINTENANCE, deleteMaintenanceSaga);
};
