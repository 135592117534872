import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import todoSagas from './todo/saga';
import chatSagas from './chat/saga';
import surveyListSagas from './surveyList/saga';
import surveyDetailSagas from './surveyDetail/saga';
import {
  watchPostNewEvent,
  watchUpdateEvent,
  watchDeleteEvent,
} from './calendar/saga';
import {
  watchPostNewMaintenance,
  watchUpdateMaintenance,
  watchDeleteMaintenance,
} from './maintenance/saga';
import {
  watchPostNewMachine,
  watchUpdateMachine,
  watchDeleteMachine,
  watchVerifyData,
  watchPostMultipleMachines,
} from './machines/saga';

export default function* rootSaga() {
  yield all([
    authSagas(),
    todoSagas(),
    chatSagas(),
    surveyListSagas(),
    surveyDetailSagas(),
    watchPostNewMachine(),
    watchUpdateMachine(),
    watchDeleteMachine(),
    watchVerifyData(),
    watchPostMultipleMachines(),
    watchPostNewEvent(),
    watchUpdateEvent(),
    watchDeleteEvent(),
    watchPostNewMaintenance(),
    watchUpdateMaintenance(),
    watchDeleteMaintenance(),
  ]);
}
