export const POST_NEW_MACHINE = 'POST_NEW_MACHINE';
export const POST_NEW_MACHINE_SUCCESS = 'POST_NEW_MACHINE_SUCCESS';
export const POST_NEW_MACHINE_ERROR = 'POST_NEW_MACHINE_ERROR';
export const UPDATE_MACHINE = 'UPDATE_MACHINE';
export const UPDATE_MACHINE_SUCCESS = 'UPDATE_MACHINE_SUCCESS';
export const UPDATE_MACHINE_ERROR = 'UPDATE_MACHINE_ERROR';
export const DELETE_MACHINE = 'DELETE_MACHINE';
export const DELETE_MACHINE_SUCCESS = 'DELETE_MACHINE_SUCCESS';
export const DELETE_MACHINE_ERROR = 'DELETE_MACHINE_ERROR';
export const VERIFY_DATA = 'VERIFY_DATA';
export const POST_MULTIPLE_MACHINES = 'POST_MULTIPLE_MACHINES';
export const POST_MULTIPLE_MACHINES_SUCCESS = 'POST_MULTIPLE_MACHINES_SUCCESS';
export const POST_MULTIPLE_MACHINES_ERROR = 'POST_MULTIPLE_MACHINES_ERROR';

export const postNewMachine = (payload) => {
  return {
    type: POST_NEW_MACHINE,
    payload,
  };
};

export const postNewMachineSuccess = (responseData) => {
  return {
    type: POST_NEW_MACHINE_SUCCESS,
    payload: responseData,
  };
};

export const postNewMachineError = (error) => {
  return {
    type: POST_NEW_MACHINE_ERROR,
    payload: error.response.data,
  };
};

export const updateMachine = (responseData) => {
  console.log('accion', responseData);
  try {
    return {
      type: UPDATE_MACHINE,
      payload: responseData,
    };
  } catch (error) {
    return {
      type: UPDATE_MACHINE_ERROR,
      error,
    };
  }
};

export const updateMachineSuccess = (responseData) => {
  return {
    type: UPDATE_MACHINE_SUCCESS,
    payload: responseData,
  };
};

export const updateMachineError = (error) => {
  return {
    type: UPDATE_MACHINE_ERROR,
    payload: error.response.data,
  };
};

export const deleteMachine = (machineId) => {
  return {
    type: DELETE_MACHINE,
    payload: machineId,
  };
};

export const deleteMachineSuccess = (responseData) => {
  return {
    type: DELETE_MACHINE_SUCCESS,
    payload: responseData,
  };
};

export const deleteMachineError = (error) => {
  return {
    type: DELETE_MACHINE_ERROR,
    payload: error.response.data,
  };
};

export const verifyData = (data) => ({
  type: VERIFY_DATA,
  payload: data,
});

export const postMultipleMachines = (payload) => {
  console.log('accion inicialized', payload);
  return {
    type: POST_MULTIPLE_MACHINES,
    payload,
  };
};

export const postMultipleMachinesSuccess = (responseData) => {
  console.log('accion succes', responseData);
  return {
    type: POST_MULTIPLE_MACHINES_SUCCESS,
    payload: responseData,
  };
};

export const postMultipleMachinesError = (error) => {
  return {
    type: POST_MULTIPLE_MACHINES_ERROR,
    payload: error.response.data,
  };
};
