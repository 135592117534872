import {
  POST_NEW_EVENT,
  POST_NEW_EVENT_SUCCESS,
  POST_NEW_EVENT_ERROR,
  UPDATE_EVENT,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_ERROR,
  DELETE_EVENT,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_ERROR,
} from './actions';

const initialState = {
  allEvents: {},
  loading: false,
  postError: null,
  updateError: null,
  deleteError: null,
  postResponse: null,
  updateResponse: null,
  deleteResponse: null,
};

const calendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_NEW_EVENT:
    case UPDATE_EVENT:
    case DELETE_EVENT:
      return {
        ...state,
        loading: true,
        postError: null,
        updateError: null,
        deleteError: null,
      };

    case POST_NEW_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        postResponse: action.payload,
      };

    case UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        updateResponse: action.payload,
      };

    case DELETE_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteResponse: action.payload,
      };

    case POST_NEW_EVENT_ERROR:
      return {
        ...state,
        loading: false,
        postError: action.payload,
      };

    case UPDATE_EVENT_ERROR:
      return {
        ...state,
        loading: false,
        updateError: action.payload,
      };

    case DELETE_EVENT_ERROR:
      return {
        ...state,
        loading: false,
        deleteError: action.payload,
      };

    default:
      return state;
  }
};

export default calendarReducer;
