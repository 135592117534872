import {
  POST_NEW_MACHINE,
  POST_NEW_MACHINE_SUCCESS,
  POST_NEW_MACHINE_ERROR,
  UPDATE_MACHINE,
  UPDATE_MACHINE_SUCCESS,
  UPDATE_MACHINE_ERROR,
  DELETE_MACHINE,
  DELETE_MACHINE_SUCCESS,
  DELETE_MACHINE_ERROR,
  VERIFY_DATA,
  POST_MULTIPLE_MACHINES,
  POST_MULTIPLE_MACHINES_SUCCESS,
  POST_MULTIPLE_MACHINES_ERROR,
} from './actions';

const initialState = {
  allMachinesDelegations: {},
  loading: false,
  postError: null,
  updateError: null,
  deleteError: null,
  postResponse: null,
  updateResponse: null,
  deleteResponse: null,
  verifiedData: [],
  postMultipleMachinesResponse: null,
  postMultipleMachinesError: null,
};

const machineReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_NEW_MACHINE:
    case POST_MULTIPLE_MACHINES:
    case UPDATE_MACHINE:
    case DELETE_MACHINE:
      return {
        ...state,
        loading: true,
        postError: null,
        updateError: null,
        deleteError: null,
        postMultipleMachinesError: null,
      };

    case POST_NEW_MACHINE_SUCCESS:
      return {
        ...state,
        loading: false,
        postResponse: action.payload,
      };

    case POST_MULTIPLE_MACHINES_SUCCESS:
      return {
        ...state,
        loading: false,
        postMultipleMachinesResponse: action.payload,
      };

    case UPDATE_MACHINE_SUCCESS:
      return {
        ...state,
        loading: false,
        updateResponse: action.payload,
      };

    case DELETE_MACHINE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteResponse: action.payload,
      };

    case VERIFY_DATA: // Agrega el manejo de VERIFY_DATA aquí
      console.log('reducer', action.payload);
      return {
        ...state,
        loading: false,
        verifiedData: action.payload,
      };

    case POST_NEW_MACHINE_ERROR:
      return {
        ...state,
        loading: false,
        postError: action.payload,
      };

    case POST_MULTIPLE_MACHINES_ERROR:
      return {
        ...state,
        loading: false,
        postMultipleMachinesError: action.payload,
      };

    case UPDATE_MACHINE_ERROR:
      return {
        ...state,
        loading: false,
        updateError: action.payload,
      };

    case DELETE_MACHINE_ERROR:
      return {
        ...state,
        loading: false,
        deleteError: action.payload,
      };

    default:
      return state;
  }
};

export default machineReducer;
