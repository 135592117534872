/* eslint-disable no-use-before-define */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { getCurrentUser } from '../../helpers/Utils';
import {
  CHAT_GET_CONTACTS,
  CHAT_GET_CONVERSATIONS,
  CHAT_ADD_MESSAGE_TO_CONVERSATION,
  CHAT_CREATE_CONVERSATION,
} from '../actions';

import {
  getContactsSuccess,
  getContactsError,
  getConversationsSuccess,
  getConversationsError,
} from './actions';

import { apiPath } from '../../constants/defaultValues';

const apiUrlContacts = `${apiPath}/contacts_for_messages`;
const apiUrlMessages = `${apiPath}/load_renter_messages`;
const apiUrlNewMessage = `${apiPath}/send_crm_message`;

function* loadContacts() {
  try {
    // eslint-disable-next-line no-use-before-define
    const response = yield call(loadContactsAsync);
    const currentUser = getCurrentUser();
    const contacts = response.data;
    yield put(getContactsSuccess(contacts, currentUser));
  } catch (error) {
    yield put(getContactsError(error));
  }
}

const loadContactsAsync = async () => {
  const currentUserRenterID = getCurrentUser().alquiladorID;
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    success(
      axios.get(`${apiUrlContacts}?renter_id=${currentUserRenterID}`, {
        headers: { api_key: process.env.REACT_APP_API_KEY },
      })
    );
  })
    .then((response) => response)
    .catch((error) => error);
};

function* loadConversations(userId) {
  const currentUserRenter = getCurrentUser().alquilador;
  try {
    // eslint-disable-next-line prefer-const
    let urlParams = new URLSearchParams(window.location.search);
    // eslint-disable-next-line prefer-const
    let emailFromLeads = urlParams.get('email');
    // eslint-disable-next-line prefer-const
    let submissionFromLeads = urlParams.get('submission');
    // eslint-disable-next-line prefer-const
    let response = yield call(loadConversationsAsync, userId);
    let searchConversation;
    if (response.data.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      searchConversation = response.data[0].users[1];
    } else {
      searchConversation = '';
    }
    if (emailFromLeads !== null) {
      searchConversation = null;
    }
    response.data.forEach(function (el) {
      if (el.users.includes(emailFromLeads)) {
        searchConversation = emailFromLeads;
      }
    });

    if (searchConversation != null) {
      yield put(getConversationsSuccess(response.data, searchConversation));
    } else {
      const currentTime = new Date().toISOString().slice(0, 19);
      // eslint-disable-next-line prefer-const
      let allConversations = response.data;
      // eslint-disable-next-line prefer-const
      let conversation = {
        id: `${submissionFromLeads}_${currentUserRenter}`,
        users: [currentUserRenter, emailFromLeads],
        lastMessageTime: currentTime,
        messages: [],
      };
      allConversations.splice(0, 0, conversation);
      yield put(getConversationsSuccess(allConversations, emailFromLeads));
    }
  } catch (error) {
    yield put(getConversationsError(error));
  }
}

const loadConversationsAsync = async () => {
  const currentUserRenterID = getCurrentUser().alquiladorID;
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    success(
      axios.get(`${apiUrlMessages}?renter_id=${currentUserRenterID}`, {
        headers: { api_key: process.env.REACT_APP_API_KEY },
      })
    );
  })
    .then((response) => response)
    .catch((error) => error);
};

function* addMessageToConversation({ payload }) {
  try {
    const { currentUserId, selectedUserId, message, file, allConversations } =
      payload;
    const response = yield call(
      // eslint-disable-next-line no-use-before-define
      addMessageToConversationAsync,
      currentUserId,
      selectedUserId,
      message,
      file,
      allConversations
    );
    const { conversations, selectedUser } = response;
    yield put(getConversationsSuccess(conversations, selectedUser));
  } catch (error) {
    yield put(getConversationsError(error));
  }
}
const addMessageToConversationAsync = async (
  currentUser,
  selectedUser,
  message,
  file,
  allConversations
  // eslint-disable-next-line consistent-return
) => {
  const conversation = allConversations.find(
    (x) =>
      x.users.includes(currentUser.alquilador) &&
      x.users.includes(selectedUser.email)
  );
  const currentTime = new Date().toISOString().slice(0, 19);
  if (conversation) {
    conversation.messages.push({
      sender: currentUser.alquilador,
      datetime: currentTime,
      message,
    });
    if (file !== null) {
      window.analytics.track('Enviar mensaje CRM con adjunto', {
        alquilador: currentUser.alquilador,
        usuario: currentUser.email,
        mensaje: message,
        ID: selectedUser.submissionID,
        alquiladorID: currentUser.alquiladorID,
      });
      conversation.messages.push({
        sender: currentUser.alquilador,
        datetime: currentTime,
        message: `Archivo enviado: ${file.name}`,
      });
    } else {
      window.analytics.track('Enviar mensaje CRM', {
        alquilador: currentUser.alquilador,
        usuario: currentUser.email,
        mensaje: message,
        ID: selectedUser.submissionID,
        alquiladorID: currentUser.alquiladorID,
      });
    }
    conversation.lastMessageTime = currentTime;
    const conversations = allConversations.filter(
      (x) => x.id !== conversation.id
    );
    conversations.splice(0, 0, conversation);
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('message', message);
      formData.append('user_id', currentUser.uid);
      formData.append('submission_id', selectedUser.submissionID);
      formData.append('renter_email', currentUser.email);
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          api_key: process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify({
          message,
          user_id: currentUser.uid,
          submission_id: selectedUser.submissionID,
          renter_email: currentUser.email,
        }),
      };
      fetch(`${apiUrlNewMessage}`, requestOptions).then(
        // registrar mensaje en base de datos
        function (response) {
          if (response.status === 200) {
            // si el mensaje se registró correctamente
            const mensajeAdjunto = `Archivo adjunto enviado: ${file.name}.`;
            const requestOptionsFile = {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                api_key: process.env.REACT_APP_API_KEY,
              },
              body: JSON.stringify({
                message: mensajeAdjunto,
                user_id: currentUser.uid,
                submission_id: selectedUser.submissionID,
                renter_email: currentUser.email,
              }),
            };
            fetch(`${apiUrlNewMessage}`, requestOptionsFile).then(
              // registrar mensaje con adjunto en base de datos
              function () {
                if (response.status === 200) {
                  // si se registró correctamnete el mensaje con adjunto
                  const requestOptionsIntegromat = {
                    method: 'POST',
                    body: formData,
                  };
                  fetch(
                    'https://hook.integromat.com/slk43xxs0iimauoi5bc5lgy8pi0w7vkz',
                    requestOptionsIntegromat
                  );
                }
              }
            );
          } else {
            alert('Ha habido algún error, ponte en contacto con nosotros');
          }
        }
      );
    } else {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          api_key: process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify({
          message,
          user_id: currentUser.uid,
          submission_id: selectedUser.submissionID,
          renter_email: currentUser.email,
        }),
      };
      fetch(`${apiUrlNewMessage}`, requestOptions).then(function (response) {
        if (response.status === 200) {
          const requestOptionsIntegromat = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              message,
              user_id: currentUser.uid,
              submission_id: selectedUser.submissionID,
              renter_email: currentUser.email,
            }),
          };
          fetch(
            'https://hook.integromat.com/slk43xxs0iimauoi5bc5lgy8pi0w7vkz',
            requestOptionsIntegromat
          );
        }
      });
    }

    // eslint-disable-next-line no-return-await
    return await new Promise((success) => {
      setTimeout(() => {
        success({ conversations, selectedUser: selectedUser.email });
      }, 500);
    })
      .then((response) => response)
      .catch((error) => error);
  }
};

function* createNewConversation({ payload }) {
  try {
    const { currentUserId, selectedUserId, allConversations } = payload;
    const response = yield call(
      // eslint-disable-next-line no-use-before-define
      createNewConversationAsync,
      currentUserId,
      selectedUserId,
      allConversations
    );
    const { conversations, selectedUser } = response;
    yield put(getConversationsSuccess(conversations, selectedUser));
  } catch (error) {
    yield put(getConversationsError(error));
  }
}

const createNewConversationAsync = async (
  currentUserId,
  selectedUserId,
  allConversations
) => {
  const currentTime = new Date().toISOString().slice(0, 19);
  const conversation = {
    id: allConversations.length + 1,
    users: [currentUserId, selectedUserId],
    lastMessageTime: currentTime,
    messages: [],
  };

  allConversations.splice(0, 0, conversation);
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    setTimeout(() => {
      success({
        conversations: allConversations,
        selectedUser: selectedUserId,
      });
    }, 500);
  })
    .then((response) => response)
    .catch((error) => error);
};

export function* watchGetContact() {
  yield takeEvery(CHAT_GET_CONTACTS, loadContacts);
}
export function* watchGetConversation() {
  yield takeEvery(CHAT_GET_CONVERSATIONS, loadConversations);
}
export function* watchAddMessageToConversation() {
  yield takeEvery(CHAT_ADD_MESSAGE_TO_CONVERSATION, addMessageToConversation);
}
export function* watchCreateConversation() {
  yield takeEvery(CHAT_CREATE_CONVERSATION, createNewConversation);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetContact),
    fork(watchGetConversation),
    fork(watchAddMessageToConversation),
    fork(watchCreateConversation),
  ]);
}
